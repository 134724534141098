<style scoped>
.imglist {align-items: center !important;vertical-align: middle !important;}
.minheight{min-height: 84px !important;}
/* */
.theme--light.v-card{color:#292929}
.theme--dark *{color:#ffffff !important;}
/* .theme--light.v-card.free{background:#ffffff}
.theme--light.v-card.plus{background:#ffeab6}
.theme--light.v-card.pro{background:#d0f0fd}
/* .theme--light.v-card.enterprise{background:#66BB6A}  */
.v-card.recomended{background:#bae6ff;transform:scale(1.1)} 
.theme--dark .v-card.recomended{background:#2ea6f5;color: #000;}
.v-card button{background: #4caf50;color: #fff;}
/* .theme--dark .v-card button{color: #000;} */
.theme--dark .v-card .v-btn{color: #ffffff !important; font-weight: bold !important;}
.theme--dark .v-card {color: #FFFFFF !important;}
.theme--dark .v-card.recomended button{background: #2a2e32;color: #2ea6f5;}
::v-deep .v-btn {letter-spacing:0 !important}
::v-deep .v-card:hover {transform: scale(1.05);border: 3px solid #2EA6F5;z-index: 1;}
::v-deep .v-card {transition: all 0.3s; color: #5E5E5E !important;border: 3px solid transparent}
/* .display-1,.body-1,.subtitle-1,.title{font-family: segoeui !important;} */
.letter-spacing{letter-spacing: 0.8px !important;}
.v-card.selectedPlan{transform: scale(1.05);border: 3px solid #2EA6F5}
</style>

<template>
 <div class="appColor">
    <div class="fsize20 text-center font-weight-bold mt-14">Choose the subscription plan</div>
    <!-- <v-responsive max-width="1180" class="mx-auto">
      <v-layout column wrap>
        <v-flex xs12 class="my-3"><div class="text-xs-center"></div></v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-start justify-start  class="pb-6">
              <v-flex xs12 sm12 md6 lg3 xl3>
                <v-card class="p-3 rounded-lg free"  height="540">
                  <div class="pa-4">
                    <div class="font-weight-bold">Free</div>
                    <p class="primaryColor whiteclr title pa-0 subtitle-1 mt-2 minheight">For individuals or teams just getting started with airtable </p>
                    <div class="font-weight-bold display-1">Free</div>
                    <div>
                      <v-btn class="primaryColor mt-2" outlined height="40" width="100%">Sign Up</v-btn>
                    </div>
                    <div class="mt-5">
                      <span class="primaryColor font-weight-bold">Free</span>
                      <span class="primaryColor ml-2">Includes:</span>
                    </div>
                    <div class="mt-2">
                      <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">Option Chain</span>
                    </div>
                    <div><span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">FII DII Data</span></div>
                    <div>
                      <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">Event Calender</span>
                      <div><span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">Result Calender</span>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md6 lg3 xl3>
                <v-card class="p-3 rounded-lg plus"  height="540">
                  <div class="pa-4">
                    <div class="font-weight-bold">Plus</div>
                     <p class="primaryColor whiteclr title pa-0 subtitle-1 mt-2 minheight">For growing teams that need more space and advanced features </p>
                    <div><span class="font-weight-bold mt-6 display-1">$10</span></div>
                    <div class="mt-2">
                    <v-btn class="" outlined height="40" width="100%">Try for free</v-btn>
                    </div>
                    <div class="mt-5">
                      <span class="">Everything in </span>
                      <span class="font-weight-bold">Free</span>
                      <span class="ml-1">plus:</span>
                    </div>
                    <div class="mt-2">
                      <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">Option Chain</span>
                    </div>
                    <div>
                      <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">FII DII Data</span>
                    </div>
                    <div>
                      <span><img class="imglist"  src="@/assets/images/dot.svg"  alt="" /></span><span class="ml-4">Event Calender</span>
                      <div>
                        <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">Result Calender</span>
                      </div>
                    </div>
                  </div>
                </v-card>
                
              </v-flex>
              <v-flex xs12 sm12 md6 lg3 xl3>
                <v-card class="p-3 rounded-lg pro recomended"  height="540">
                  <div class="pa-4">
                    <div class="font-weight-bold">Pro</div>
                   <p class="primaryColor whiteclr title pa-0 subtitle-1 mt-2 minheight"> For teams and companies that need to collaborate on complex workflows</p>
                    <div class="font-weight-bold mt-0 display-1">$20</div>
                    <div class="mt-2"><v-btn class="" outlined height="40" width="100%">Try for free</v-btn></div>
                    <div class="mt-5">
                      <span class="font-weight-bold">Free</span>
                      <span class="ml-2">Includes</span>
                    </div>
                    <div class="mt-2">
                      <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">Option Chain</span>
                    </div>
                    <div>
                      <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">FII DII Data</span>
                    </div>
                    <div>
                      <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">Event Calender</span>
                      <div>
                        <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span ><span class="ml-4">Result Calender</span>
                      </div>
                    </div>
                  </div>
                </v-card>
                 
              </v-flex>
              <v-flex xs12 sm12 md6 lg3 xl3>
                <v-card class="p-3  enterprise" height="540">
                  <div class="pa-4">
                    <div class="font-weight-bold">Enterprise</div>
                  <p class="primaryColor whiteclr title pa-0 subtitle-1 mt-2 minheight"> For companies with advanced security, control, and support needs </p>
                    <div>
                      <span class="font-weight-bold mt-0 display-1">$30</span>
                    </div>
                    <div class="mt-2">
                      <v-btn outlined height="40" width="100%">Sign Up </v-btn>
                    </div>
                    <div class="mt-5">
                      <span class="font-weight-bold">Free</span>
                      <span class="text-white ml-2">Includes</span>
                    </div>
                    <div class="mt-2">
                      <span><img class="imglist" src="@/assets/images/dot.svg"  alt="" /></span><span class="ml-4">Option Chain</span>
                    </div>
                    <div>
                      <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span ><span class="ml-4">FII DII Data</span>
                    </div>
                    <div>
                      <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">Event Calender</span>
                      <div>
                        <span><img class="imglist" src="@/assets/images/dot.svg" alt="" /></span><span class="ml-4">Result Calender</span>
                      </div>
                    </div>
                  </div>
                </v-card>
                </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-responsive> -->
   <v-responsive  max-width="1180" class="mx-auto">
   <v-row class="ma-0 py-8">
   <v-col v-for="(item, index) in plans" :key="index" class="pa-0">
   <v-hover v-slot="{ hover }" >
     <!-- :height="item.packTitle == 'Annual' ? '550' : ''"@mouseover="mouseOver(item)" @mouseleave="mouseLeave(item)" -->
   <v-card :class="item.selected == true ? 'selectedPlan' : 'unSelectedPlan'" class="pa-3 my-3 mx-auto rounded-lg" width="270" elevation="4" @mouseover="mouseOver(item)"  @mouseleave="mouseLeave(item)">
     <div class="d-flex flex-column justify-center">
       <div class="text-center">
       <div class="font-weight-bold title blue--text" >{{item.packTitle}}</div>
       <p class="mt-4 black--text letter-spacing"><span class=" display-2 font-weight-bold" v-if="item.price != 'Free'">&#8377; {{item.price}}</span><span class="body-2"> + GST</span></p>
       <p class="mt-2 font-weight-bold body-2 letter-spacing">{{item.duration}}</p>
       <div>
         <v-btn :class="item.selected == true ? 'primary white--text' : ''" :color="hover ? 'primary white--text': 'black--text'" min-width="170" height="38" depressed class="text-capitalize font-weight-bold mt-2">{{item.btnName}}</v-btn>
       </div>
       <p class="mt-4 body-2 text-left pl-10 font-weight-bold">List of Features</p>
       </div>
       <div v-for="(i,idx) in item.availableOptions" :key="idx">
         <div class="d-flex flex-column justify-center">
         <div class="body-2 pb-1 px-8"><span class="body-2 font-weight-bold">&#10003;</span> {{i}}</div>
         </div>
       </div>
       </div>
   </v-card>
   </v-hover>
   </v-col>
   </v-row>
   </v-responsive>
 </div>
</template>

<script>
export default {
  data: () => ({
    plans : [{ packTitle: '7 Days Trial',desc: 'For individuals or teams just getting started with Amoga' ,
    price: "199" , duration: 'Trial for 7 Calendar days', btnName:"Subscribe" , availableOptions: ["Positions & Orders","Option Chain ",'Basket Orders','Straddle Charts','OI & COI','Max Pain, PCR','Future Analytics','F&O News & More ...'],selected:false},
    { packTitle: 'Monthly',desc: 'For individuals or teams just getting started with Amoga' ,
    price: "599" ,duration: 'Basic Plan', btnName:"Subscribe" , availableOptions: ["Positions & Orders","Option Chain ",'Basket Orders','Straddle Charts','OI & COI','Max Pain, PCR','Future Analytics','F&O News & More ...'],selected:true},
    { packTitle: 'Half Yearly',desc: 'For individuals or teams just getting started with Amoga' ,
    price: "2999" ,duration: 'Savings of 18%', btnName:"Subscribe" , availableOptions: ["Positions & Orders","Option Chain ",'Basket Orders','Straddle Charts','OI & COI','Max Pain, PCR','Future Analytics','F&O News & More ...'],selected:false},
    { packTitle: 'Annual',desc: 'For individuals or teams just getting started with Amoga' ,
    price: "4999" ,duration: 'Savings of 36%', btnName:"Subscribe" , availableOptions: ["Positions & Orders","Option Chain ",'Basket Orders','Straddle Charts','OI & COI','Max Pain, PCR','Future Analytics','F&O News & More ...'],selected:false}],
    currentPlan:'Annual'
  }),
  methods: {
     mouseOver(value) {
       this.plans.forEach(el =>{
        if(el.packTitle != value.packTitle) {
          el.selected = false
         }
      })
     },
     mouseLeave(value) {
       this.plans.forEach(el =>{
         if(el.packTitle == 'Monthly') {
           el.selected = true
         }else{
           el.selected = false
         }
       })
     },
  }
};
</script>


